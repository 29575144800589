// import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar';
import Category from './components/category';
import OurElevators from './components/ourElev';
import WhatElse from './components/whatElse';
import MapComponent from './components/mapComponent';
import ContactInfo from './components/contactInfo';
import About from './components/aboutUs';
import Footer from './components/footer';
import WhatsAppIcon from './components/whatsappicon';
import HighLights from './components/highlights';
import Signature from './components/signature';
import logo from './statics/images/logo.jpeg';
function App() {
  return (
    <div className="App">
      <Navbar />
      {/* <HighLights /> */}
      <Category />
      {/* <Signature /> */}
      <OurElevators />
      <WhatElse />
      <About />
      <ContactInfo />
      <WhatsAppIcon />
      <Footer />
    </div>
  );
}

export default App;
