import React from "react";
import passgImg from '../statics/images/psgone.jpg';
import homeElev from '../statics/images/homeElev.jpg';
import carElev from '../statics/images/carElev.jpg'
import capsuleElev from '../statics/images/capsule.jpg'

const Category=()=>{


    return(
        <>
        <div id="category" className="categoryCnt">
            <div className="categoryText">
                <span>Category</span>
            </div>
            <div className="categoryItemList">
                <div className="categoryItem">
                    <img src={passgImg} width='100%' height='70%' />
                    <span>Passanger Elevator</span>
                </div>
                <div className="categoryItem">
                    <img src={homeElev} width='100%' height='70%' />
                    <span>Home Elevator</span>
                </div>
                <div className="categoryItem">
                    <img src={carElev} width='100%' height='70%' />
                    <span>Car Elevator</span>
                </div>
                <div className="categoryItem">
                    <img src={capsuleElev} width='100%' height='70%' />
                    <span>Capsule Elevator</span>
                </div>
            </div>
        </div>
        </>
    )
}


export default Category;