import React from "react";
import { SocialIcon } from 'react-social-icons'
import '../statics/css/navbarr.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone,faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Sbar from "./sidebar";
import hl1 from '../statics/images/hl1.jpeg';

const Navbar=()=>{

    return(
        <>
        <div id="home" className="imgContainer">
      <img 
        className="imageCover" 
        src="https://img.freepik.com/premium-photo/luxury-building-elevator-modern-elevator-skyscraper-generative-ai_634358-802.jpg" 
        // src="https://img.freepik.com/premium-photo/luxury-building-elevator-modern-elevator-skyscraper-generative-ai_634358-802.jpg" 
        alt="Luxury Building" 
        width="100%" 
        height="500px" 
      />
      <div className="nav">
        <div className="social">
          <a href="https://instagram.com" className="socials">
            <SocialIcon url="https://instagram.com" style={{ height: '30px', width: '30px' }} />
          </a>
          <a href="https://linkedin.com" className="socials">
            <SocialIcon url="https://linkedin.com" style={{ height: '30px', width: '30px' }} />
          </a>
          <a href="https://facebook.com" className="socials">
            <SocialIcon url="https://facebook.com" style={{ height: '30px', width: '30px' }} />
          </a>
          <a href="https://twitter.com" className="socials">
            <SocialIcon url="https://twitter.com" style={{ height: '30px', width: '30px' }} />
          </a>
          <span className="socials phone">
          <FontAwesomeIcon icon={faPhone} /><b> +447588740586</b>
        </span>
        <span className="socials email">
          <FontAwesomeIcon icon={faEnvelope} /><b> avukaj@adi-installation.com</b>
        </span>
        </div>
      </div>
      <div className="menuContainer">
      <Sbar />
        <div className="leftmenu">
            <a  href="#home">Home</a>
            <a  href="#category">Category</a>
            <a  href="#ourElevators">Our Elevators</a>
        </div>
        <div className="rightmenu">
            <a href="#whatElse">What Else We Offer</a>
            <a  href="#aboutus">About Us</a>
            <a  href="">More </a>
        </div>
      </div>
      <div className="imageText">
        <span>Reliable and Efficient Elevators</span>
      </div>
    </div>
        </>
    )
}


export default Navbar;