import React from "react";
import passgImg from '../statics/images/psgone.jpg';
import homeElev from '../statics/images/homeElev.jpg';
import carElev from '../statics/images/carElev.jpg'
import capsuleElev from '../statics/images/capsule.jpg'
import our14 from '../statics/images/ourElev/our14.jpeg'//3
import our15 from '../statics/images/ourElev/our15.jpeg'//1
import our10 from '../statics/images/ourElev/our10.jpeg'//2
import our11 from '../statics/images/ourElev/our11.jpeg'//4
const WhatElse=()=>{


    return(
        <>
        <div id="whatElse" className="categoryCntw">
            <div className="categoryText">
                <span>What Else We Offer</span>
            </div>
            <div className="categoryItemListw">
                <div className="categoryItemw">
                    <img src={our15} width='100%' height='50%' />
                    <span>Annual Maintance Contract</span>
                    <p className="serviceDesc">
                    We specialize in providing dependable and trusted elevator maintenance services. Our company offers tailored annual maintenance contracts for a wide variety of lift systems.                    </p>
                </div>
                <div className="categoryItemw">
                    <img src={our10} width='100%' height='50%' />
                    <span>Installation</span>
                    <p className="serviceDesc">
                    Installation projects are executed following consultations on the appropriate lift types needed to suit the building's requirements, utilizing state-of-the-art technology and equipment.                    </p>
                </div>
                <div className="categoryItemw">
                    <img src={our14} width='100%' height='50%' />
                    <span>Modification</span>
                    <p className="serviceDesc">
                    We upgrade our clients' elevators with cutting-edge techniques, technology, modern designs, and efficient features to enhance safety and minimize energy consumption.                    </p>
                </div>
                <div className="categoryItemw">
                    <img src={our11} width='100%' height='50%' />
                    <span>Repair And Services</span>
                    <p className="serviceDesc">
                    We have a team of skilled professionals who offer repair and maintenance services at competitive rates, ensuring the optimal performance of elevators and their internal components.                    </p>
                </div>
            </div>
        </div>
        </>
    )
}


export default WhatElse;