import React from "react";
import aboutImg from '../statics/images/about.jpg';

const About=()=>{



    return(
        <>
        <div id="aboutus" >
            
            <div className="forInfosa" style={{'padding':'50px'}}>
                <div className="aboutDesc" style={{'width':'100%','height':'100%', 'textAlign':'center'}}>
                    <h2>About Us</h2>
                    <p className="aboutP">
                    We, at ADI INSTALLATION LTD, located in 34 Riverdene road, Edgware, LONDON, England, are a prominent provider specializing in elevators of all types and sizes. Our mission is to enhance the daily lives of urban residents by improving mobility. Whether you're looking for residential or commercial elevators, our expert team is here to help you select and install the perfect solution for your needs. If you're uncertain about which options best suit your building, we're just a phone call away to guide you.
                    </p>
                </div>
            </div>
            <div className="forabout">
                <img src={aboutImg} /> 
            </div>
        </div>
        </>
    )
}


export default About;