import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone,faWhatsapp } from '@fortawesome/free-solid-svg-icons';

const WhatsAppIcon=()=>{
    const phoneNumber = '+447588740586'; // Replace with your phone number
    const message = 'Hello, I am interested about';
    const encodedMessage = encodeURIComponent(message); // Encode the message

    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    return(
        <>
            <div className="whatsAppCnt">
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faPhone} />        
            </a>    
            </div>
        
        </>
    )
}

export default WhatsAppIcon;