import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import passgImg from '../statics/images/psgone.jpg';
import our1 from '../statics/images/ourElev/our1.jpeg'
import our2 from '../statics/images/ourElev/our2.jpeg'
import our3 from '../statics/images/ourElev/our3.jpeg'
import our4 from '../statics/images/ourElev/our4.jpeg'
import our5 from '../statics/images/ourElev/our5.jpeg'
import our6 from '../statics/images/ourElev/our6.jpeg'
import our7 from '../statics/images/ourElev/our7.jpeg'
import our8 from '../statics/images/ourElev/our8.jpeg'
import our9 from '../statics/images/ourElev/our9.jpeg'
import our10 from '../statics/images/ourElev/our10.jpeg'//2
import our11 from '../statics/images/ourElev/our11.jpeg'//4
import our12 from '../statics/images/ourElev/our12.jpeg'
import our13 from '../statics/images/ourElev/our13.jpeg'
import our14 from '../statics/images/ourElev/our14.jpeg'//3
import our15 from '../statics/images/ourElev/our15.jpeg'//1


// import 'swiper/swiper-bundle.min.css'; // Import Swiper styles
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';




const OurElevators=()=>{


 const  breakpoints={
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      }


    return(
        <>
        <div id="ourElevators" className="categoryCnt">
            <div className="categoryText">
                <span>Our Elevators</span>
            </div>
            <Swiper
            modules={[Navigation]}
            navigation
            spaceBetween={50}
            slidesPerView={4}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            breakpoints={breakpoints}
            >
            <SwiperSlide>
                <div className="categoryItemS">
                    <img src={our1} width='100%' height='100%' />
                    {/* <span>Passanger Elevator</span> */}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="categoryItemS">
                    <img src={our2} width='100%' height='100%' />
                    {/* <span>Passanger Elevator</span> */}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                 <div className="categoryItemS">
                    <img src={our3} width='100%' height='100%' />
                    {/* <span>Passanger Elevator</span> */}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="categoryItemS">
                    <img src={our4} width='100%' height='100%' />
                    {/* <span>Passanger Elevator</span> */}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="categoryItemS">
                    <img src={our5} width='100%' height='100%' />
                    {/* <span>Passanger Elevator</span> */}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="categoryItemS">
                    <img src={our6} width='100%' height='100%' />
                    {/* <span>Passanger Elevator</span> */}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                 <div className="categoryItemS">
                    <img src={our7} width='100%' height='100%' />
                    {/* <span>Passanger Elevator</span> */}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="categoryItemS">
                    <img src={our8} width='100%' height='100%' />
                    {/* <span>Passanger Elevator</span> */}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                 <div className="categoryItemS">
                    <img src={our9} width='100%' height='100%' />
                    {/* <span>Passanger Elevator</span> */}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="categoryItemS">
                    <img src={our10} width='100%' height='100%' />
                    {/* <span>Passanger Elevator</span> */}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="categoryItemS">
                    <img src={our11} width='100%' height='100%' />
                    {/* <span>Passanger Elevator</span> */}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="categoryItemS">
                    <img src={our12} width='100%' height='100%' />
                    {/* <span>Passanger Elevator</span> */}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                 <div className="categoryItemS">
                    <img src={our13} width='100%' height='100%' />
                    {/* <span>Passanger Elevator</span> */}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="categoryItemS">
                    <img src={our14} width='100%' height='100%' />
                    {/* <span>Passanger Elevator</span> */}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="categoryItemS">
                    <img src={our15} width='100%' height='100%' />
                    {/* <span>Passanger Elevator</span> */}
                </div>
            </SwiperSlide>
            </Swiper>
        </div>
        </>
    )
}

export default OurElevators;