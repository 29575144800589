import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone,faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { SocialIcon } from 'react-social-icons'
import EmailPopup from "./emailpopup";
import PhonePopup from "./phonepopup";

const Footer=()=>{
    const phoneNumber='+447588740586';
    const emailAddress='arditshyti05@gmail.com';

    const [popupVisible, setPopupVisible] = useState(false);
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [popupPhoneVisible, setPopupPhoneVisible] = useState(false);

    const popupRef = useRef(null);
    const phpopunRef=useRef(null);

    let popupStyles={
        position: 'fixed',
        left: popupPosition.x,
        top: `calc(${popupPosition.y}px - 106px)`,
        backgroundColor: 'white',
        border: '1px solid #ccc',
        padding: '10px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
    }

    const handleEmailClick = (event) => {
        setPopupVisible(true);
        const { clientX, clientY } = event;
        setPopupPosition({ x: clientX, y: clientY });
    };

    const handlePhoneClick = (event) => {
        setPopupPhoneVisible(true);
        const { clientX, clientY } = event;
        setPopupPosition({ x: clientX, y: clientY });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setPopupVisible(false); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (phpopunRef.current && !phpopunRef.current.contains(event.target)) {
                setPopupPhoneVisible(false); 
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    return(
        <>
        <div className="footerContainer">
            <div className="usefulLinks">
                <h3>Useful Links</h3>
                <a className="usfLinks" href="#category">Category</a>
                <a className="usfLinks" href="#whatElse">What Else We Offer</a>
                <a className="usfLinks" href="">Gallery</a>
            </div>
            <div className="usefulLinks">
                <h3>Useful Links</h3>
                <a className="usfLinks" href="#home">Home</a>
                <a className="usfLinks" href="#aboutus">About Us</a>
                <a className="usfLinks" href="#ourElevators">Our Elevators</a>
            </div>
            <div className="usefulLinks">
                <h3>Contact</h3>
                <a className="usfLinks" onClick={handlePhoneClick} >+447588740586</a>
                {popupPhoneVisible && (
                <PhonePopup
                    popupStyles={popupStyles}
                    phoneNumber={phoneNumber}
                    onClose={() => setPopupPhoneVisible(false)}
                    position={popupPosition}
                    popupRef={phpopunRef}
                />
                )}
                <a className="usfLinks" onClick={handleEmailClick} >avukaj@adi-installation.com</a>
                {popupVisible && (
                <EmailPopup
                popupStyles={popupStyles}
                    email={emailAddress}
                    onClose={() => setPopupVisible(false)}
                    position={popupPosition}
                    popupRef={popupRef}
                />
            )}
            </div><div className="usefulLinks">
                <h3>Connect</h3>
                <a href="https://instagram.com" className="socials">
                    <SocialIcon url="https://instagram.com" style={{ height: '30px', width: '30px' }} />
                </a>
                <a href="https://linkedin.com" className="socials">
                    <SocialIcon url="https://linkedin.com" style={{ height: '30px', width: '30px' }} />
                </a>
                <a href="https://facebook.com" className="socials">
                    <SocialIcon url="https://facebook.com" style={{ height: '30px', width: '30px' }} />
                </a>
                <a href="https://twitter.com" className="socials">
                    <SocialIcon url="https://twitter.com" style={{ height: '30px', width: '30px' }} />
                </a>
            </div>
        </div>
        </>
    )
}

export default Footer;