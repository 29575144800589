import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot,faEnvelope,faPhone,faClock } from '@fortawesome/free-solid-svg-icons';
import MapComponent from "./mapComponent";

const ContactInfo=()=>{



    return(
        <>
        <div className="contactContainer">
            <div className="forMap">
                <MapComponent />
            </div>
            <div className="forInfos">
                <div className="location">
                    <div className="iconCnt">
                        <FontAwesomeIcon icon={faLocationDot} style={{ height: '100%', width: '100%' }} />
                    </div>
                    <div className="cntDescription">
                        <h3>Our Office Address</h3>
                        <h5>Gjata Accounting Ltd First Floor,Building 2, Croxley Park, Watford, England, WD18 8YA</h5>
                    </div>
                </div>
                <div className="location">
                    <div className="iconCnt">
                        <FontAwesomeIcon icon={faEnvelope} style={{ height: '100%', width: '100%' }} />
                    </div>
                    <div className="cntDescription">
                        <h3>General Enquiries</h3>
                        <h5>adivukaj@adi-installation.com</h5>
                    </div>
                </div><div className="location">
                    <div className="iconCnt">
                    <FontAwesomeIcon icon={faPhone}  style={{ height: '100%', width: '100%' }} />
                    </div>
                    <div className="cntDescription">
                        <h3>Call Us</h3>
                        <h5>00447588740586</h5>
                    </div>
                </div><div className="location">
                    <div className="iconCnt">
                    <FontAwesomeIcon icon={faClock}  style={{ height: '100%', width: '100%' }} />
                    </div>
                    <div className="cntDescription">
                        <h3>Our Timing</h3>
                        <h5>Mon - Fri : 08:00 AM - 05:00 PM</h5>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default ContactInfo;