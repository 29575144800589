import React, { useState } from 'react';

const EmailPopup = ({ email, onClose,popupRef,popupStyles }) => {
    const copyToClipboard = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(email)
                .then(() => alert('Email copied to clipboard!'))
                .catch(err => console.error('Could not copy text: ', err));
        } else {
            // Fallback for older browsers
            const textArea = document.createElement('textarea');
            textArea.value = email;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                alert('Email copied to clipboard!');
            } catch (err) {
                console.error('Fallback: Could not copy text: ', err);
            } finally {
                document.body.removeChild(textArea);
            }
        }
        onClose();
    };

    const subject = 'Your Subject Here';
    const body = 'Your message body goes here.';

    return (
        <div style={popupStyles} ref={popupRef} className="email-popup">
            <button onClick={copyToClipboard}>Copy Email</button>
            <a href={`mailto:${email}`} onClick={onClose}>
                Send Email From Device
            </a>
            <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`} 
                target="_blank" 
                rel="noopener noreferrer">
                Send Email from Google
            </a>
        </div>
    );
};

export default EmailPopup;