import React, { useState } from 'react';

const PhonePopup = ({ phoneNumber, onClose,popupRef,popupStyles }) => {
    const copyToClipboard = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(phoneNumber)
                .then(() => alert('Phone number copied to clipboard!'))
                .catch(err => console.error('Could not copy text: ', err));
        } else {
            // Fallback for older browsers
            const textArea = document.createElement('textarea');
            textArea.value = phoneNumber;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                alert('Phone number copied to clipboard!');
            } catch (err) {
                console.error('Fallback: Could not copy text: ', err);
            } finally {
                document.body.removeChild(textArea);
            }
        }
        onClose();
    };

    return (
        <div style={popupStyles} ref={popupRef} className="email-popup">
            <button onClick={copyToClipboard}>Copy Number</button>
            <a href={`tel:${phoneNumber}`} onClick={onClose}>
                Call Us
            </a>
        </div>
    );
};

export default PhonePopup;