import React, { useEffect, useRef, useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars,faPhone,faEnvelope } from '@fortawesome/free-solid-svg-icons';



const StyledSidebar = styled(Sidebar)`
    width: 100%;
    height: 100vh;
`;

const Sbar = () => {
    const [openSubMenus, setOpenSubMenus] = useState({});
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const sidebarRef = useRef(null);

    const toggleSubMenu = (label) => {
        setOpenSubMenus((prev) => ({
            ...prev,
            [label]: !prev[label],
        }));
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsSidebarOpen(false);
        }
    };

    const [isNavbarVisible, setNavbarVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            // Check how far the user has scrolled
            if (window.scrollY > 30) {
                setNavbarVisible(false); // Hide navbar if scrolled down more than 30px
            } else {
                setNavbarVisible(true); // Show navbar if scrolled back to top
            }
        };

        window.addEventListener('scroll', handleScroll);
        
        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        // Add event listener for clicks outside
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Cleanup the event listener on unmount
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
        <div className={`sbuttonCnt ${!isNavbarVisible ? 'hidden' : ''}`} >
            <button className="sidebutton" onClick={toggleSidebar}>
                <FontAwesomeIcon icon={faBars} />
            </button>
            <span className="socials phone">
          <b> Adi Elevators</b>
        </span>
        {/* <span className="socials phone">
          <FontAwesomeIcon icon={faPhone} /><b> +3551234567891</b>
        </span>
        <span className="socials email">
          <FontAwesomeIcon icon={faEnvelope} /><b> mail@companyname.com</b>
        </span> */}
        </div>
            {isSidebarOpen && (
                <div ref={sidebarRef} className="sbarCnt">
                    <StyledSidebar>
                        <Menu>
                            <MenuItem onClick={()=>setIsSidebarOpen(false)}><a  href="#home">Home</a></MenuItem>
                            <MenuItem onClick={()=>setIsSidebarOpen(false)}> <a  href="#category">Category</a> </MenuItem>
                            <MenuItem onClick={()=>setIsSidebarOpen(false)}> <a  href="#ourElevators">Our Elevators</a> </MenuItem>
                            <MenuItem onClick={()=>setIsSidebarOpen(false)}><a href="#whatElse">What Else We Offer</a></MenuItem>
                            <MenuItem onClick={()=>setIsSidebarOpen(false)}><a  href="#aboutus">About Us</a></MenuItem>
                        </Menu>
                    </StyledSidebar>
                </div>
            )}
        </>
    );
}

export default Sbar;
